.demo {
  * {
    font-family: 'Roboto';
  }

  &__content {
    width: 60%;
    margin: 0 auto;
  }

  &__logo {
    width: 32px;
    margin-right: 12px;

    img {
      @include fit-image;
    }
  }

  &__title {
    display: inline-block;
  }

  &__section-header {
    display: flex;
    align-items: center;
  }

  &__section {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: inherit;
  }

  &__user-list {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  &__user-card {
    display: inline-flex;
    align-items: center;
    background-color: #84909b;
    padding: 10px 20px;
    border-radius: 10px;
    margin: 0 4px;

    img {
      width: 128px;
      height: 128px;
      border-radius: 50%;
      margin-right: 20px;
    }
  }

  &__user-card-details {
    dd {
      display: block;

      &:last-child {
        margin-top: 10px;
      }
    }
  }
}
