.page {
  width: 70%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding-top: 300px;

  @include desktop {
    width: 50%;
  }

  &__img-container {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    margin-bottom: 64px;

    @include tablet {
      width: 85%;
    }

    @include desktop {
      width: 80%;
    }
  }

  &__address {
    font-size: $text4;
    line-height: $lHeight4;
  }

  &__text {
    margin-bottom: 112px;
    font-size: $text4;
    line-height: $lHeight4;
  }

  &__mail {
    color: $grey30;
    text-decoration: underline;
    font-size: $text4;
    line-height: $lHeight4;
  }

  &--bold {
    font-weight: bold;
  }
}
