@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

* {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  width: 100%;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: $black;
  font-size: 1.6rem;
  background-color: $yellow;
}

main {
  min-height: 100vh;
}

img {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

i {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}
